import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";

export const query = graphql`
  query {
    sprzedazwgpozycji: file(relativePath: { eq: "sprzedaz-wg-pozycji.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rankingkontrahentow: file(
      relativePath: { eq: "ranking-kontrahentow.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    przyczynyutraty: file(relativePath: { eq: "przyczyny-utraty.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    obrotyph: file(relativePath: { eq: "obroty-ph.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    listazlecen: file(relativePath: { eq: "lista-zlecen-kontrahenci.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lejeksprzedazy: file(
      relativePath: { eq: "lejek-sprzedazy-wartosciowo.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    konwersja: file(relativePath: { eq: "konwersja-lejka.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kategorieproduktowe: file(
      relativePath: { eq: "klienci-dla-kategorii-produktowej.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    czasposwiecony: file(
      relativePath: { eq: "czas-poswiecony-na-zlecenie.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aktywnosc: file(
      relativePath: { eq: "aktywnosc-handlowcow-system-dla-agencji.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Raportowanie = ({ data }) => {
  return (
    <FunctionPageLayout title="Raportowanie" headline="Sprzedaż i realizacja">
      <SEO
        title="Raportowanie w agencji reklamowej"
        description="Automatyczne raporty ✅ Monitorowanie pracy handlowców • Monitorowanie statusu zleceń • Zapytaj o bezpłatne demo!"
        keywords={["Raportowanie"]}
      />
      <p>
        <strong>Agencje reklamowe</strong> cechują się ciągłym rozwojem i
        poszukiwaniem nowych, lepszych sposobów na realizację stawianych przed
        nimi zleceń. Aby odnosić sukcesy na tym polu, trzeba{" "}
        <strong>analizować obecne procesy</strong>, przeszłe wydarzenia oraz{" "}
        <strong>aktywność pracowników</strong>. Wyciągnięte z tych analiz{" "}
        <strong>wnioski to podstawa do optymalizacji i dalszego rozwoju</strong>
        . Empireo wesprze Cię w tym wszystkim dzięki{" "}
        <strong>automatycznym raportom</strong>.
      </p>

      <br />
      <h3>Analiza kampanii sprzedaży</h3>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[
          data.lejeksprzedazy,
          data.konwersja,
          data.przyczynyutraty,
          data.aktywnosc,
        ]}
        alts={[
          "Lejek sprzedaży w systemie dla agencji reklamowej, Konwersja lejka sprzedaży w systemie dla agencji marketingowej, Przyczyny utraty szans sprzedaży w programie dla agencji reklamowej, aktywność handlowców w systemie do zarządzania agencją reklamową",
        ]}
      />
      <h3>Przykładowe raportowanie zleceń</h3>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.listazlecen, data.czasposwiecony]}
        alts={[
          "lista zleceń w systemie dla agencji reklamowej, Czas poświęcony na zlecenie w systemie do zarządzania agencją",
        ]}
      />
      <h3>Inne przykładowe raporty</h3>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[
          data.sprzedazwgpozycji,
          data.rankingkontrahentow,
          data.obrotyph,
          data.kategorieproduktowe,
        ]}
        alts={[
          "Raport sprzedaż miesięczna, Raport ranking kontrahentów, Raport obroty handlowców, Raport kategorii produktowych",
        ]}
      />

      <h3>Wybrane raporty: </h3>
      <p>
        <ul>
          <li>
            <strong>Sprzedaż według kategorii</strong> to raport ukazujący
            wartość sprzedaży w zależności od kategorii, kontrahentów,
            produktów, usług itp.
          </li>
          <li>
            <strong>Ofertowanie według kategorii</strong> - czyli raport
            wskazujący wartość ofert złożonych w określonym czasie.
          </li>
          <li>
            <strong>Aktywność handlowców</strong>, który w graficzny sposób
            przedstawia aktywność handlowców według typów zrealizowanych przez
            nich zadań.
          </li>
          <li>
            <strong>Utracone szanse</strong> - czyli raport wskazujący utracone
            transakcje wraz z powodem ich utraty oraz komentarzem od pracownika.
          </li>
          <li>
            <strong>Pracochłonność zleceń według typów i kontrahentów</strong>,
            dzięki któremu sprawdzisz, czy współpraca z danym klientem jest
            rentowna i na jakiego rodzaju zleceniach warto się skupić.
          </li>
        </ul>
      </p>
      <p
        style={{
          marginTop: "30px",
        }}
      >
        Dzięki zaimplementowanym w Empireo automatycznym raportom będziesz mieć
        łatwy wgląd w to, <strong>co robią Twoi handlowcy</strong>. Będziesz
        mógł analizować, jakiego rodzaju produkty i usługi sprzedaje agencja
        oraz jakie osiąga obroty. Automatyczne raporty na temat zleceń{" "}
        <strong>wskażą ich koszty czasowe i rentowność współpracy</strong>.
      </p>
    </FunctionPageLayout>
  );
};

export default Raportowanie;
